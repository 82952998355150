import { useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const Ping = ({ config, onConfigUpdate, navigate }) => {
	useEffect(() => {
		setInterval(() => {
			if (process.env.DEV) console.log('ping');
			axios
				.post(`${process.env.APP_SERVER}`, {
					action: 'ping',
				})
				.then((response) => {
					if (config.isAuthenticated && !response.data.authenticated) {
						onConfigUpdate({ isAuthenticated: false });
						navigate('/logout', { replace: true });
					}
					if (process.env.DEV) console.log('pong', response.data);
				})
				.catch((error) => {
					console.log(error);
				});
		}, 1000 * 60 * 5);
	}, []);
};

Ping.propTypes = {
	config: PropTypes.object,
	onConfigUpdate: PropTypes.func,
	navigate: PropTypes.func,
};

export default Ping;
