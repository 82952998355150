export function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export const prettyPrintFilesize = (bytes, units = ['B', 'KB', 'MB', 'GB', 'TB']) => {
	if (bytes === 0) {
		return '0 B';
	}
	const i = Math.floor(Math.log(bytes) / Math.log(1024));
	const size = Math.floor((bytes / 1024 ** i) * 10) / 10;
	return `${size} ${units[i]}`;
};
