import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const Trigger = ({ title, icon }) => (
	<>
		<span className={`icon ${icon}`}></span>
		<span className="label">
			<FormattedMessage id={title}></FormattedMessage>
		</span>
	</>
);

Trigger.propTypes = {
	title: PropTypes.string,
	icon: PropTypes.string,
};

export default Trigger;
