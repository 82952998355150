import React from 'react';
import PropTypes from 'prop-types';

const InputSelectOption = (props) => {
	const { data, children, className, cx, getStyles, isDisabled, isFocused, isSelected, innerRef, innerProps } = props;
	return (
		<div
			ref={innerRef}
			style={getStyles('option', props)}
			className={cx(
				{
					option: true,
					'option--is-disabled': isDisabled,
					'option--is-focused': isFocused,
					'option--is-selected': isSelected,
					'option--is-first': data.top,
				},
				className,
			)}
			{...innerProps}
		>
			{children}
		</div>
	);
};

InputSelectOption.propTypes = {
	children: PropTypes.string,
	className: PropTypes.string,
	cx: PropTypes.func,
	data: PropTypes.object,
	getStyles: PropTypes.func,
	innerProps: PropTypes.object,
	innerRef: PropTypes.func,
	isDisabled: PropTypes.bool,
	isFocused: PropTypes.bool,
	isSelected: PropTypes.bool,
};

export default InputSelectOption;
