import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import HTMLMessage from './HTMLMessage';
import { sleep, prettyPrintFilesize } from '../modules/Utils';

const InputFile = (props) => {
	const maxFileSize = 1024 * 1024 * 12;
	const { form, field, label, format } = props;

	const intl = useIntl();
	const memoryUnits = ['B', 'KB', 'MB', 'GB', 'TB'].map((unit) =>
		intl.formatMessage({ id: `app.memory_unit.${unit}` }),
	);
	const uploadedFiles = intl.formatMessage({ id: 'forms.file_uploaded' });

	const initialState = {
		focus: false,
		error: null,
		file: null,
		fileSizeServer: 0,
		fileSizeRemain: maxFileSize,
		isDragging: false,
		isUploading: false,
		isFirstFile: true,
		progress: 0,
		cancelTokenSource: axios.CancelToken.source(),
		uploadedFiles: [],
	};

	if (form.values[field.name].ZDOKSIZE !== undefined && form.values[field.name].ZDOKSIZE !== '') {
		initialState.fileSizeServer = form.values[field.name].ZDOKSIZE;
		initialState.fileSizeRemain = maxFileSize - initialState.fileSizeServer;
	}

	initialState.isDroppable =
		initialState.fileSizeServer < maxFileSize &&
		(form.values[field.name].DATEINAME === '' || (form.values[field.name].BASE64 === 'S' && format === 'pdf'));

	const reducer = (state, newState) => ({ ...state, ...newState });
	const [state, setState] = useReducer(reducer, initialState);
	const { focus, isUploading, isDroppable } = state;
	const prettyRemainingSize = prettyPrintFilesize(state.fileSizeRemain, memoryUnits);

	// console.log(form.values[field.name]);

	const classes = classNames({
		dropzone: true,
		dragging: state.isDragging,
		shake: !state.isDragging && state.error,
		focus: !!focus,
		error: state.error || (form.touched[field.name] && form.errors[field.name]),
		additional: state.fileSizeServer > 0,
	});

	useEffect(
		() => () => {
			if (state.cancelTokenSource) state.cancelTokenSource.cancel();
		},
		[],
	);

	const onDragEnter = () => {
		setState({ isDragging: true });
	};

	const onDragLeave = () => {
		setState({ isDragging: false });
	};

	const onDrop = (data) => {
		setState({ isDragging: false });
		validateAndSetFile(data[0]);
	};

	const onRemove = () => {
		axios.post(
			`${process.env.APP_SERVER}`,
			{ action: 'delete', DOKUMENTE: [{ ART: field.name, BASE64: '', DATEINAME: '' }] },
			{
				cancelToken: state.cancelTokenSource.token,
			},
		);

		const fileObj = form.values[field.name];
		fileObj.BASE64 = '';
		fileObj.DATEINAME = '';
		form.setFieldValue(field.name, fileObj);
		setState({ isDroppable: true, fileSizeServer: 0, fileSizeRemain: maxFileSize });
	};

	const validateAndSetFile = (file) => {
		if (!file) return;

		// file size and format
		let error = null;
		const fileTypes = format === 'jpg' ? ['image/png', 'image/jpeg'] : ['image/png', 'image/jpeg', 'application/pdf'];
		if (fileTypes.indexOf(file.type) === -1) {
			error = format === 'jpg' ? 'forms.file_format_image' : 'forms.file_format';
		} else if (file.size > state.fileSizeRemain) {
			error = state.fileSizeServer > 0 ? 'forms.file_toobig_remain' : 'forms.file_toobig';
		}

		// handle file dropped
		if (!error) {
			setState({ error: null });
			upload(file);
			return;
		}

		setState({ error, file: null });
	};

	const upload = (file) => {
		const formData = new FormData();
		formData.append('id', field.name);
		formData.append('format', format);
		formData.append('file', file);

		const startTime = new Date().getTime();
		setState({ file, isUploading: true, progress: 5 });
		axios
			.post(`${process.env.APP_SERVER}`, formData, {
				cancelToken: state.cancelTokenSource.token,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				onUploadProgress: (progressEvent) => {
					const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
					setState({ progress: percent });
				},
			})
			.then((result) => {
				if (process.env.DEV) console.log(result.data);
				const delayArtificially = 1000;
				sleep(delayArtificially - (new Date().getTime() - startTime)).then(() => {
					if (result.data.error === -1) setState({ isUploading: false, error: 'forms.file_error' });
					if (result.data.error === -2) setState({ isUploading: false, error: 'forms.file_malware' });
					if (result.data.error === -3)
						setState({
							isUploading: false,
							error: format === 'jpg' ? 'forms.file_format_image' : 'forms.file_format',
						});
					if (result.data.error === -4) setState({ isUploading: false, error: 'forms.file_badpdf' });
					if (result.data.error < -4) setState({ isUploading: false, error: 'forms.file_error' });
					if (result.data.error === 0) {
						const fileObj = form.values[field.name];
						fileObj.BASE64 = 'S';
						fileObj.DATEINAME = result.data.filename;
						fileObj.ZDOKSIZE = result.data.filesize;
						fileObj.ZDOKS = result.data.files;
						const canDrop = format === 'pdf' && result.data.filesize < maxFileSize;
						setState({
							isUploading: false,
							isDroppable: canDrop,
							progress: 100,
							fileSizeServer: result.data.filesize,
							fileSizeRemain: maxFileSize - result.data.filesize,
						});
						form.setFieldValue(field.name, fileObj);
					}
				});
			})
			.catch(() => {
				setState({ error: 'forms.file_error', file: null, isUploading: false });
			});
	};

	if (isUploading)
		return (
			<div className="input-file uploading" data-name={field.name}>
				<a href="#dummy" className={`disabled file ${state.file.name.split('.').pop().toLowerCase()}`}>
					{state.file.name}
				</a>
				<div className="progress-percent">{parseInt(state.progress, 10)}%</div>
				<div className="progress">
					<div className="progress-bar" style={{ width: `${state.progress}%` }}></div>
				</div>
			</div>
		);

	return (
		<>
			{field.value.DATEINAME !== '' && (
				<>
					<div className="input-file with-file" data-name={field.name}>
						{field.value.BASE64 === '' ? (
							<a href="#dummy" className={`disabled file ${field.value.DATEINAME.split('.').pop().toLowerCase()}`}>
								{uploadedFiles}
							</a>
						) : (
							<a
								href={`${process.env.APP_SERVER}/download/${field.name}`}
								target="_blank"
								className={`file ${field.value.DATEINAME.split('.').pop().toLowerCase()}`}
							>
								{uploadedFiles}
							</a>
						)}
						<button type="button" onClick={onRemove}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
								<g fill="#086adb">
									<path d="M18 11v12a1 1 0 002 0V11a1 1 0 00-2 0zm-3-1a.9.9 0 00-1 1v12a1 1 0 002 0V11a.9.9 0 00-1-1zm-4 0a.9.9 0 00-1 1v12a.9.9 0 001 1 .9.9 0 001-1V11a.9.9 0 00-1-1z" />
									<path d="M25 6h-5V3a.9.9 0 00-1-1h-8a.9.9 0 00-1 1v3H5a.9.9 0 00-1 1 .9.9 0 001 1h1v19a.9.9 0 001 1h16a.9.9 0 001-1V8h1a.9.9 0 001-1 .9.9 0 00-1-1zM12 4h6v2h-6zm10 22H8V8h14z" />
								</g>
							</svg>
						</button>
					</div>
					{field.value.ZDOKS && field.value.ZDOKS.length > 0 && (
						<ul className="filelist">
							{field.value.ZDOKS.map((file, index) => (
								<li key={index}>{file}</li>
							))}
						</ul>
					)}
				</>
			)}
			{isDroppable && (
				<div className="input-file" data-name={field.name}>
					<Dropzone onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop} multiple={false}>
						{({ getRootProps, getInputProps }) => (
							<section className={classes}>
								<div {...getRootProps()} tabIndex="-1">
									<input {...getInputProps()} />
									<p>
										{state.fileSizeServer === 0 && <FormattedMessage id={label} />}
										{state.fileSizeServer > 0 && <FormattedMessage id="forms.file_additional" />}
									</p>
									<p className="small small-or">
										<FormattedMessage id="forms.documents_caption_or" />
									</p>
									<button className="button file" type="button">
										<FormattedMessage id="button.select_file" />
									</button>
									{state.fileSizeServer > 0 && (
										<p className="small">
											<HTMLMessage id="forms.file_remain" values={{ filesize: prettyRemainingSize }} />
										</p>
									)}
								</div>
							</section>
						)}
					</Dropzone>
					{state.error && (
						<div className="message">
							<FormattedMessage id={state.error} values={{ filesize: prettyRemainingSize }}></FormattedMessage>
						</div>
					)}
					{!state.error && form.touched[field.name] && form.errors[field.name] && (
						<div className="message">
							<FormattedMessage id={form.errors[field.name]}></FormattedMessage>
						</div>
					)}
				</div>
			)}
		</>
	);
};

InputFile.propTypes = {
	config: PropTypes.object,
	field: PropTypes.object,
	form: PropTypes.object,
	format: PropTypes.string.isRequired,
	label: PropTypes.string,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default InputFile;
