import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Layout from './Layout';

const Confirmed = ({ config, onConfigUpdate }) => (
	<Layout title="app.title" config={config} onConfigUpdate={onConfigUpdate}>
		<div className="content form login">
			<div className="content-inner">
				<h1 className="smaller">
					<FormattedMessage id={config.status === 'VERIFY_D' ? 'confirmed.title_d' : 'confirmed.title'} />
				</h1>
				<p className="medium">
					<FormattedMessage id={config.status === 'VERIFY_D' ? 'confirmed.text_d' : 'confirmed.text'} />
				</p>
				<p>
					<a className="link white" href="mailto:hr.advisory@swisscom.com">
						hr.advisory@swisscom.com
					</a>
				</p>
			</div>
		</div>
	</Layout>
);

Confirmed.propTypes = {
	config: PropTypes.object,
	onConfigUpdate: PropTypes.func,
};

export default Confirmed;
