import * as Yup from 'yup';
import { isString } from 'lodash';
import { isPast } from 'date-fns';

const personal = 1;
const uploads = 2;

class Schema {
	static load(section, values) {
		const shape = {};

		switch (section) {
			case personal:
				return Yup.object().shape({
					ANRED: Yup.mixed()
						.default('')
						.test('mixed', 'forms.anrede_error', (value) => {
							if (isString(value) && value === '') return false;
							if (parseInt(value, 10) <= 0) return false;
							return true;
						}),

					VORNA: Yup.string().default('').required('forms.vorname_error').min(2, 'forms.vorname_error'),

					NACHN: Yup.string().default('').required('forms.nachname_error').min(2, 'forms.nachname_error'),

					GBDAT: Yup.string()
						.default('0000-00-00')
						.required('forms.geburtsdatum_error')
						.test('date', 'forms.geburtsdatum_error', (value) => value !== '' && value !== '0000-00-00')
						.test('date_past', 'forms.geburtsdatum_past', (value) => isPast(new Date(value))),

					STRAS: Yup.string().required('forms.strasse_error').min(2, 'forms.strasse_error'),

					PSTLZ: Yup.string().required('forms.required_generic').min(4, 'forms.required_generic'),

					ORT01: Yup.string().required('forms.required_generic').min(2, 'forms.required_generic'),

					LAND1: Yup.string().required('forms.land_error'),

					NATIO: Yup.string().required('forms.staatsangeh_error'),

					STATE: Yup.string().when('LAND1', {
						is: 'CH',
						then: () => Yup.string().required('forms.kanton_error'),
					}),

					SPRSL: Yup.string().default('').required('forms.sprache_error').min(1, 'forms.sprache_error'),
				});

			case uploads:
				// ID/Pass
				if (values.DOK01.REQUIRED === 'X')
					shape.DOK01 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Passphoto
				if (values.DOK02.REQUIRED === 'X')
					shape.DOK02 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Betreibungsregisterauszug
				if (values.DOK03.REQUIRED === 'X')
					shape.DOK03 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Strafregisterauszug
				if (values.DOK04.REQUIRED === 'X')
					shape.DOK04 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Vertraulichkeitserklärung
				if (values.DOK05.REQUIRED === 'X')
					shape.DOK05 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Sicherheitserklärung
				if (values.DOK06.REQUIRED === 'X')
					shape.DOK06 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				// Sicherheitsbestimmung
				if (values.DOK07.REQUIRED === 'X')
					shape.DOK07 = Yup.object().test('file', 'forms.file_empty', (file) => validateFile(file));

				return Yup.object().shape(shape);

			default:
				console.log('Schema load: unknown section', section);
				return Yup.object();
		}
	}

	static get personal() {
		return personal;
	}

	static get uploads() {
		return uploads;
	}
}

const validateFile = (file) => file.DATEINAME !== '' && file.DATEINAME !== undefined;

export default Schema;
