import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const HTMLMessage = ({ id, values }) => {
	const intl = useIntl();

	let translated = intl.formatMessage({ id });
	if (values && values.link) {
		translated = translated.replace('{link}', values.link);
	}
	if (values && values.filesize) {
		translated = translated.replace('{filesize}', values.filesize);
	}

	return (
		<span
			dangerouslySetInnerHTML={{
				__html: translated,
			}}
		/>
	);
};

HTMLMessage.propTypes = {
	id: PropTypes.string,
	values: PropTypes.object,
};

export default HTMLMessage;
