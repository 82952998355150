import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ErrorMessage } from 'formik';

const InputRadioGroup = ({ name, label, vertical, children }) => (
	<div className={`radio-group${vertical ? ' vertical' : ''}`}>
		{label && (
			<label htmlFor={name}>
				<FormattedMessage id={label}></FormattedMessage>
			</label>
		)}
		<div className="radio-inner">{children}</div>
		<ErrorMessage
			name={name}
			render={(message) => (
				<div className="message">
					<FormattedMessage id={message}></FormattedMessage>
				</div>
			)}
		/>
	</div>
);

InputRadioGroup.propTypes = {
	children: PropTypes.array,
	label: PropTypes.string,
	name: PropTypes.string,
	vertical: PropTypes.bool,
};

export default InputRadioGroup;
