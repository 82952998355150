import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { FormattedMessage } from 'react-intl';

const ModalConfirm = (props) => {
	const getParent = () => document.querySelector('#app');

	return (
		<Modal
			parentSelector={getParent}
			isOpen={props.isOpen}
			onRequestClose={props.handleConfirmCancel}
			shouldCloseOnOverlayClick={false}
			shouldCloseOnEsc={!props.isLoading}
			className="modal dialog"
			overlayClassName="overlay"
			portalClassName="modal-container"
			contentLabel="Confirmation dialog"
			ariaHideApp={false}
			closeTimeoutMS={500}
		>
			<h3>
				<FormattedMessage id={props.title} />
			</h3>
			<p>
				<FormattedMessage id={props.text} />
			</p>
			<div className="button-group align-bottom">
				<button
					className={props.isLoading ? 'button secondary disabled' : 'button secondary'}
					type="button"
					onClick={props.handleConfirmCancel}
				>
					<FormattedMessage id="button.cancel" />
				</button>
				<button className={props.isLoading ? 'button spinner' : 'button'} type="button" onClick={props.handleConfirmOk}>
					<FormattedMessage id="button.submit" />
					<div className="loader-spinner--small"></div>
				</button>
			</div>
			<button
				type="button"
				onClick={props.handleConfirmCancel}
				className={props.isLoading ? 'modal-close disabled' : 'modal-close'}
			>
				&nbsp;
			</button>
		</Modal>
	);
};

ModalConfirm.propTypes = {
	handleConfirmCancel: PropTypes.func.isRequired,
	handleConfirmOk: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	isOpen: PropTypes.bool.isRequired,
	text: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};

export default ModalConfirm;
