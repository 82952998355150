import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Ping from '../components/Ping';
import ScrollToTop from '../components/ScrollToTop';
import Language from '../components/Language';

const Layout = ({ title, children, config, onConfigUpdate }) => {
	const intl = useIntl();
	const navigate = useNavigate();

	const logout = () => {
		onConfigUpdate({ isAuthenticated: false });
		axios
			.post(`${process.env.APP_SERVER}`, { action: 'logout' })
			.then((result) => {
				if (process.env.DEV) console.log(result);
			})
			.catch((error) => {
				if (process.env.DEV) console.log(error);
			})
			.finally(() => {
				navigate('/logout', { replace: true });
			});
	};

	return (
		<div className="main" tabIndex="-1">
			<FormattedMessage id={title}>
				{(localizedTitle) => (
					<Helmet>
						<title>{`${localizedTitle}`}</title>
					</Helmet>
				)}
			</FormattedMessage>

			<div key="header-meta" className="header-meta">
				<Language config={config} onConfigUpdate={onConfigUpdate} />
			</div>

			<div key="header" className="header">
				<div className="wrap logo">
					<span key="logo-lifeform" className="logo-lifeform" aria-label="Swisscom Logo"></span>
					<span className="logo-type">
						<img alt="Swisscom" src="/images/swisscom-logo-type.svg" />
					</span>
				</div>
				{config && config.isAuthenticated && (
					<button className="button transparent logout" type="button" onClick={logout}>
						<FormattedMessage id="button.logout"></FormattedMessage>
					</button>
				)}
			</div>

			<div className="wrap content-wrap">
				<div id="content" className="main">
					{children}
					<div className="bg"></div>
					<div className="bg2"></div>
				</div>
			</div>

			<div className="footer">
				<div className="wrap">
					<p>
						<a href={intl.formatMessage({ id: 'app.impressum.link' })} tabIndex="-1">
							<FormattedMessage id="app.impressum"></FormattedMessage>
						</a>
						<span> | </span>
						<a href={intl.formatMessage({ id: 'app.datenschutz.link' })} tabIndex="-1">
							<FormattedMessage id="app.datenschutz"></FormattedMessage>
						</a>
					</p>
					<p>&copy; Swisscom {new Date().getFullYear()}</p>
				</div>
			</div>
			<Ping config={config} onConfigUpdate={onConfigUpdate} navigate={navigate} />
			<ScrollToTop />
		</div>
	);
};

Layout.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	config: PropTypes.object,
	onConfigUpdate: PropTypes.func,
	title: PropTypes.string,
};

export default Layout;
