import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { ErrorMessage } from 'formik';
import Select from 'react-select';
import InputSelectOption from './InputSelectOption';

const InputSelect = ({ caption, field, form, label, labelField, name, onChange, options, placeholder, valueField }) => {
	const intl = useIntl();

	const customFilter = (option, searchText) => {
		if (searchText === '' || (option.label && option.label.toLowerCase().startsWith(searchText.toLowerCase()))) {
			return true;
		}
		return false;
	};

	return (
		<div
			data-name={field.name}
			className={`select-group${form.touched[field.name] && form.errors[field.name] ? ' error' : ''}`}
		>
			<label htmlFor={name}>
				<FormattedMessage id={label} />
			</label>
			<FormattedMessage id={placeholder}>
				{(placeholderIntl) => (
					<Select
						components={{ InputSelectOption }}
						className="react-select"
						classNamePrefix="react-select"
						name={name}
						defaultValue={options.find((element) => (element ? element.key === field.value : null))}
						placeholder={placeholderIntl}
						options={options}
						getOptionValue={(option) => option[valueField]}
						getOptionLabel={(option) => option[labelField]}
						disabled={options.length === 0}
						onChange={(option) => {
							form.setFieldValue(field.name, option.key);
							if (onChange) onChange(option.key);
						}}
						noOptionsMessage={() => intl.formatMessage({ id: 'forms.notfound' })}
						isClearable={false}
						backspaceRemovesValue={false}
						tabSelectsValue={false}
						filterOption={customFilter}
						menuPosition="auto"
						key={`key_${field.name}_${field.value}`}
					/>
				)}
			</FormattedMessage>
			<ErrorMessage
				name={field.name}
				render={(message) => (
					<div className="message">
						<FormattedMessage id={message}></FormattedMessage>
					</div>
				)}
			/>
			{caption ? (
				<span className="caption">
					<FormattedMessage id={caption} />
				</span>
			) : null}
		</div>
	);
};

InputSelect.propTypes = {
	caption: PropTypes.string,
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string.isRequired,
	labelField: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
	options: PropTypes.array,
	placeholder: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	valueField: PropTypes.string,
};

export default InputSelect;
