import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Cleave from 'cleave.js/react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { ErrorMessage } from 'formik';
import DateTimePicker from './DateTimePicker';

const Input = (props) => {
	const intl = useIntl();
	const [active, setActive] = useState(false);
	const [focus, setFocus] = useState(false);
	const { form, field, type, name, label, placeholder, caption, value, mask, hideErrors, ...rest } = props;
	const meta = form.getFieldMeta(field.name);
	const iOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

	const classes = classNames({
		'input-field': true,
		'floating-label': false,
		active: !!active,
		focus: !!focus,
		error: meta.touched && meta.error,
	});

	const onFocus = (event) => {
		setActive(true);
		setFocus(true);
		if (props.onFocus) {
			props.onFocus(event);
		}
	};

	const onBlur = (event) => {
		const element = event.target;
		element.scrollLeft = 0;
		element.scrollTop = 0;
		setActive(element.value.length !== 0);
		setFocus(false);
		if (props.onBlur) {
			props.onBlur(event);
		}
	};

	return (
		<div className={classes}>
			{label ? (
				<label htmlFor={name}>
					<FormattedMessage id={label} />
				</label>
			) : null}
			{(() => {
				const placeholderIntl = placeholder ? intl.formatMessage({ id: placeholder }) : null;
				let defaultDate;
				let date;

				switch (type) {
					case 'textarea':
						return (
							<textarea
								{...field}
								{...rest}
								type={type}
								placeholder={placeholderIntl}
								value={value || field.value || ''}
								onBlur={onBlur}
								onFocus={onFocus}
							></textarea>
						);

					case 'date':
						if (field.value && field.value.length === 10) {
							date = new Date(field.value);
							if (date.toString() !== 'Invalid Date') defaultDate = format(date, 'dd.MM.yyyy');
						}

						return (
							<div className="date-input" data-name={field.name}>
								<DateTimePicker
									{...rest}
									key={`key_${field.name}_${field.value}`}
									defaultValue={defaultDate}
									options={{
										allowInput: true,
										dateFormat: 'd.m.Y',
										defaultDate,
										monthSelectorType: 'static',
										minDate: props.minDate || '01.01.1900',
										maxDate: props.maxDate || '31.12.2048',
										locale: intl.messages.flatpickr,
										placeholder: placeholderIntl,
										field,
										mask: !iOS,
									}}
									onFocus={onFocus}
									onBlur={onBlur}
									onChange={(result) => {
										if (result.length) {
											form.setFieldValue(field.name, format(result[0], 'yyyy-MM-dd'));
										} else {
											form.setFieldValue(field.name, '');
										}
									}}
								/>
							</div>
						);

					default:
						if (mask) {
							return (
								<div className="input-field">
									<Cleave
										{...field}
										{...rest}
										placeholder={placeholderIntl}
										maxLength={rest.maxLength || 128}
										value={field.value}
										autoComplete="off"
										spellCheck="false"
										onInit={(el) => {
											el.lastInputValue = '';
										}}
										onBlur={onBlur}
										onFocus={onFocus}
										onChange={(event) => form.setFieldValue(field.name, event.target.rawValue)}
										options={mask}
									/>
								</div>
							);
						}

						return (
							<input
								{...field}
								{...rest}
								type={type}
								placeholder={placeholderIntl}
								value={value || field.value || ''}
								maxLength={rest.maxLength || 128}
								autoComplete="off"
								spellCheck="false"
								onBlur={onBlur}
								onFocus={onFocus}
							></input>
						);
				}
			})()}
			{!hideErrors && (
				<ErrorMessage
					name={field.name}
					render={(message) => (
						<div className="message">
							<FormattedMessage id={message}></FormattedMessage>
						</div>
					)}
				/>
			)}
			{caption ? (
				<span className="caption">
					<FormattedMessage id={caption} />
				</span>
			) : null}
		</div>
	);
};

Input.propTypes = {
	caption: PropTypes.string,
	field: PropTypes.object,
	form: PropTypes.object,
	hideErrors: PropTypes.bool,
	label: PropTypes.string,
	mask: PropTypes.object,
	maxDate: PropTypes.string,
	minDate: PropTypes.string,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	placeholder: PropTypes.string,
	type: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Input;
