import messagesDe from '../locale/de.json';
import messagesFr from '../locale/fr.json';
import messagesIt from '../locale/it.json';
import messagesEn from '../locale/en.json';

// add missing translations
let merge = function (key) {
	messagesFr[key] = messagesFr[key] || `[FR] ${messagesDe[key]}`;
};
Object.keys(messagesDe).map(merge);

merge = function (key) {
	messagesIt[key] = messagesIt[key] || `[IT] ${messagesDe[key]}`;
};
Object.keys(messagesDe).map(merge);

merge = function (key) {
	messagesEn[key] = messagesEn[key] || `[EN] ${messagesDe[key]}`;
};
Object.keys(messagesDe).map(merge);

const addFromPresets = function (data) {
	const result = {};
	const langs = { DE: messagesDe, FR: messagesFr, IT: messagesIt, EN: messagesEn };
	Object.keys(langs).forEach((lang) => {
		Object.keys(data).forEach((k) => {
			let array = [];
			Object.keys(data[k]).forEach((k2) => {
				if (data[k][k2].TEXT) {
					const text = data[k][k2].TEXT[lang] ? data[k][k2].TEXT[lang] : '[fehlt]';
					array.push({ key: data[k][k2].KEY, value: text });
					langs[lang][`${k}.${data[k][k2].KEY}`] = text;
				} else {
					console.log('no TEXT', k, k2, data[k][k2]);
				}
			});
			result[lang.toLowerCase()] = result[lang.toLowerCase()] ? result[lang.toLowerCase()] : {};
			result[lang.toLowerCase()][k] = array;

			if (k === 'LANDX') {
				array.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
				moveToTop(array, 'LI');
				moveToTop(array, 'CH');
			}

			if (k === 'KANTO') {
				array.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));
			}

			if (k === 'SPRSL') {
				array = [];
				const dataLang = data[k].find((el) => el.KEY === lang.substring(0, 1));
				Object.keys(dataLang.TEXT).forEach((key) => {
					array.push({ key: key.substring(0, 1), value: dataLang.TEXT[key] });
				});
			}

			langs[lang][k] = array;
		});
	});
	return result;
};

const moveToTop = function (array, key) {
	let id = false;
	array.some((el, i) => {
		if (el.key === key) {
			id = i;
			return true;
		}
		return false;
	});
	if (id) {
		const obj = array[id];
		obj.top = true;
		delete array[id];
		array.unshift(obj);
	}
};

/* flatpickr */

messagesDe.flatpickr = {
	weekdays: {
		shorthand: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
		longhand: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
	},
	months: {
		shorthand: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
		longhand: [
			'Januar',
			'Februar',
			'März',
			'April',
			'Mai',
			'Juni',
			'Juli',
			'August',
			'September',
			'Oktober',
			'November',
			'Dezember',
		],
	},
	firstDayOfWeek: 1,
	weekAbbreviation: 'KW',
	rangeSeparator: ' bis ',
	scrollTitle: 'Zum Ändern scrollen',
	toggleTitle: 'Zum Umschalten klicken',
	time_24hr: true,
};

messagesFr.flatpickr = {
	firstDayOfWeek: 1,
	weekdays: {
		shorthand: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
		longhand: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
	},
	months: {
		shorthand: ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
		longhand: [
			'Janvier',
			'Février',
			'Mars',
			'Avril',
			'Mai',
			'Juin',
			'Juillet',
			'Août',
			'Septembre',
			'Octobre',
			'Novembre',
			'Décembre',
		],
	},
	ordinal: (nth) => {
		if (nth > 1) return '';
		return 'er';
	},
	rangeSeparator: ' au ',
	weekAbbreviation: 'Sem',
	scrollTitle: 'Défiler pour augmenter la valeur',
	toggleTitle: 'Cliquer pour basculer',
	time_24hr: true,
};

messagesIt.flatpickr = {
	weekdays: {
		shorthand: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
		longhand: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
	},
	months: {
		shorthand: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
		longhand: [
			'Gennaio',
			'Febbraio',
			'Marzo',
			'Aprile',
			'Maggio',
			'Giugno',
			'Luglio',
			'Agosto',
			'Settembre',
			'Ottobre',
			'Novembre',
			'Dicembre',
		],
	},
	firstDayOfWeek: 1,
	ordinal: () => '°',
	rangeSeparator: ' al ',
	weekAbbreviation: 'Se',
	scrollTitle: 'Scrolla per aumentare',
	toggleTitle: 'Clicca per cambiare',
	time_24hr: true,
};

messagesEn.flatpickr = {
	weekdays: {
		shorthand: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
		longhand: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
	},
	months: {
		shorthand: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		longhand: [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		],
	},
	daysInMonth: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
	firstDayOfWeek: 0,
	ordinal: (nth) => {
		const s = nth % 100;
		if (s > 3 && s < 21) return 'th';
		switch (s % 10) {
			case 1:
				return 'st';
			case 2:
				return 'nd';
			case 3:
				return 'rd';
			default:
				return 'th';
		}
	},
	rangeSeparator: ' to ',
	weekAbbreviation: 'Wk',
	scrollTitle: 'Scroll to increment',
	toggleTitle: 'Click to toggle',
	amPM: ['AM', 'PM'],
	yearAriaLabel: 'Year',
	monthAriaLabel: 'Month',
	hourAriaLabel: 'Hour',
	minuteAriaLabel: 'Minute',
	time_24hr: false,
};

export const Messages = {
	de: messagesDe,
	fr: messagesFr,
	it: messagesIt,
	en: messagesEn,
	addFromPresets,
};
