import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { FormattedMessage } from 'react-intl';

const ModalComplete = (props) => {
	const getParent = () => document.querySelector('#app');

	return (
		<Modal
			parentSelector={getParent}
			isOpen={props.isOpen}
			onRequestClose={props.handleCompleteCancel}
			shouldCloseOnOverlayClick
			shouldCloseOnEsc
			className="modal dialog"
			overlayClassName="overlay"
			portalClassName="modal-container"
			contentLabel="Completion dialog"
			ariaHideApp={false}
			closeTimeoutMS={500}
		>
			<h3>
				<FormattedMessage id={props.title} />
			</h3>
			<p>
				<FormattedMessage id={props.text} />
			</p>
			<div className="button-group align-bottom">
				<button className="button secondary" type="button" onClick={props.handleCompleteCancel}>
					<FormattedMessage id="button.cancel" />
				</button>
				<button className="button" type="button" onClick={props.handleCompleteOk} style={{ maxWidth: '210px' }}>
					<FormattedMessage id="home.validate" />
				</button>
			</div>
			<button type="button" onClick={props.handleCompleteCancel} className="modal-close">
				&nbsp;
			</button>
		</Modal>
	);
};

ModalComplete.propTypes = {
	handleCompleteCancel: PropTypes.func.isRequired,
	handleCompleteOk: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	text: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};

export default ModalComplete;
