import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';
import flatpickr from 'flatpickr';

/*
	This is a class component because previousProps from before component update is needed.
*/
class DateTimePicker extends Component {
	componentDidUpdate(previousProps) {
		const { options } = this.props;
		const prevOptions = previousProps.options;

		const optionsKeys = Object.getOwnPropertyNames(options);
		for (let index = optionsKeys.length - 1; index >= 0; index -= 1) {
			const key = optionsKeys[index];
			const value = options[key];

			if (value !== prevOptions[key]) {
				this.flatpickr.set(key, value);
			}
		}

		// eslint-disable-next-line no-prototype-builtins
		if (this.props.hasOwnProperty('value') && this.props.value !== previousProps.value) {
			this.flatpickr.setDate(this.props.value, false);
		}
	}

	componentDidMount() {
		this.createFlatpickrInstance();
	}

	componentWillUnmount() {
		this.destroyFlatpickrInstance();
	}

	createFlatpickrInstance = () => {
		const options = {
			...this.props.options,
			onClose: (date) => {
				this.props.onChange(date);
			},
			onChange: (date) => {
				this.props.onChange(date);
			},
		};

		this.flatpickr = flatpickr(this.node, options);

		// eslint-disable-next-line no-prototype-builtins
		if (this.props.hasOwnProperty('value')) {
			this.flatpickr.setDate(this.props.value, false);
		}

		const { onCreate } = this.props;
		if (onCreate) onCreate(this.flatpickr);
	};

	destroyFlatpickrInstance = () => {
		const { onDestroy } = this.props;
		if (onDestroy) onDestroy(this.flatpickr);
		this.flatpickr.destroy();
		this.flatpickr = null;
	};

	handleNodeChange = (node) => {
		if (node) this.node = node.element ? node.element : node;
		if (this.flatpickr) {
			this.destroyFlatpickrInstance();
			this.createFlatpickrInstance();
		}
	};

	render() {
		const { options, defaultValue, value, children, render, ...props } = this.props;

		const callbacks = ['onCreate', 'onDestroy'];
		callbacks.forEach((callback) => {
			delete props[callback];
		});

		return options.mask ? (
			<Cleave
				{...props}
				name={options.field.name}
				type="text"
				onChange={(event) => {
					this.flatpickr.setDate(event.target.value.length === 10 ? event.target.value : null);
				}}
				className="flatpickr-input"
				placeholder={options.placeholder}
				value={defaultValue}
				options={{ date: true, delimiter: '.', datePattern: ['d', 'm', 'Y'] }}
				ref={this.handleNodeChange}
			/>
		) : (
			<input
				{...props}
				onChange={(event) => {
					if (event.target.value.length === 10) this.flatpickr.setDate(event.target.value);
				}}
				placeholder={options.placeholder}
				defaultValue={defaultValue}
				ref={this.handleNodeChange}
				maxLength="10"
			/>
		);
	}
}

DateTimePicker.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	defaultValue: PropTypes.string,
	onChange: PropTypes.func,
	onClose: PropTypes.func,
	onCreate: PropTypes.func,
	onDayCreate: PropTypes.func,
	onDestroy: PropTypes.func,
	onMonthChange: PropTypes.func,
	onOpen: PropTypes.func,
	onReady: PropTypes.func,
	onValueUpdate: PropTypes.func,
	onYearChange: PropTypes.func,
	options: PropTypes.object,
	render: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.number]),
};

DateTimePicker.defaultProps = {
	options: {},
};

export default DateTimePicker;
