import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './views/App';

if (!process.env.DEV) {
	Sentry.init({
		dsn: 'https://036d4532b33f4b40aaae4424f299de27@o447098.ingest.sentry.io/6576355',
		integrations: [new BrowserTracing()],
		tracesSampleRate: 1.0,
	});
}

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App key="app" />);
