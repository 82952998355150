import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import { format } from 'date-fns';
import PersonalDetails from './PersonalDetails';
import Uploads from './Uploads';
import Layout from './Layout';
import Data from '../modules/Data';
import Trigger from '../components/Trigger';
import ModalComplete from './ModalComplete';

const Home = (props) => {
	const location = useLocation();
	const navigate = useNavigate();
	const intl = useIntl();
	const [isCompleteOpen, setIsCompleteOpen] = useState(false);

	const validations = Data.validateSections(props.config.data);
	const initialState = {
		allSectionsAreValid: Data.areAllSectionsValid(validations),
		personalDetailsIsValid: validations.personalDetailsIsValid,
		uploadsIsValid: validations.uploadsIsValid,
		isPersonalDetailsOpen: location.search === '?personal-details',
		isUploadsOpen: location.search === '?uploads',
	};
	const reducer = (state, newState) => ({ ...state, ...newState });
	const [state, setState] = useReducer(reducer, initialState);

	useEffect(() => {
		props.onConfigUpdate({ home: '/home' });
	}, []);

	const handlePersonalDetailsOpen = () => {
		setState({
			isUploadsOpen: false,
			isPersonalDetailsOpen: true,
		});
	};

	const handlePersonalDetailsClose = () => {
		const newState = Data.validateSections(props.config.data);
		const oldValidationState = state.allSectionsAreValid;
		newState.allSectionsAreValid = Data.areAllSectionsValid(newState);
		if (newState.allSectionsAreValid && !oldValidationState) {
			setTimeout(() => setIsCompleteOpen(true), 600);
		}
		setState({ ...newState, isPersonalDetailsOpen: false });
	};

	const handleUploadsOpen = () => {
		if (state.isPersonalDetailsOpen) {
			setTimeout(() => {
				const element = document.getElementById('accordion-anchor');
				element.scrollIntoView({ block: 'start', behavior: 'smooth' });
			}, 400);
		}
		setState({
			isUploadsOpen: true,
			isPersonalDetailsOpen: false,
		});
	};

	const handleUploadsClose = () => {
		const newState = Data.validateSections(props.config.data);
		const oldValidationState = state.allSectionsAreValid;
		newState.allSectionsAreValid = Data.areAllSectionsValid(newState);
		if (newState.allSectionsAreValid && !oldValidationState) {
			setTimeout(() => setIsCompleteOpen(true), 600);
		}
		setState({ ...newState, isUploadsOpen: false });
	};

	const handleCompleteOk = () => {
		navigate('/summary', { replace: true });
	};

	const handleCompleteClose = () => setIsCompleteOpen(false);

	const label = (id) => {
		const message = intl.formatMessage({ id });
		return typeof message === 'string' ? message.replace('*', '') : id;
	};

	const anrede = intl.formatMessage({ id: 'title.generic' });

	return (
		<Layout title="app.title" {...props}>
			<div className="content form">
				<div className="content-inner">
					<h1 className="smaller">{`${anrede} ${props.config.data.VORNA}`}</h1>
					<p id="home-text">
						<FormattedMessage
							id={props.config.data.STATUS === 'EMPLOYEE_D' ? 'home.text_d' : 'home.text'}
						></FormattedMessage>
					</p>
					<div id="accordion-anchor" className="accordion-anchor"></div>
					<div id="accordion-wrap" className="accordion-wrap">
						<Collapsible
							open={state.isPersonalDetailsOpen}
							onTriggerOpening={handlePersonalDetailsOpen}
							onTriggerClosing={handlePersonalDetailsClose}
							transitionTime={200}
							trigger={
								<Trigger title="home.personal" id="personal" icon={state.personalDetailsIsValid ? 'check' : 'pencil'} />
							}
						>
							{props.config.data.STATUS === 'EMPLOYEE_D' ? (
								<table className="summary" style={{ marginTop: '-0.5rem', marginBottom: '1rem' }}>
									<tbody>
										<tr>
											<td>{label('forms.vorname')}</td>
											<td>{props.config.data.VORNA}</td>
										</tr>
										<tr>
											<td>{label('forms.nachname')}</td>
											<td>{props.config.data.NACHN}</td>
										</tr>
										<tr>
											<td>{label('forms.geburtsdatum')}</td>
											<td>{format(new Date(props.config.data.GBDAT), 'dd.MM.yyyy')}</td>
										</tr>
									</tbody>
								</table>
							) : (
								<PersonalDetails
									{...props}
									onClose={handlePersonalDetailsClose}
									onSave={handlePersonalDetailsClose}
									isOpen={state.isPersonalDetailsOpen}
								/>
							)}
						</Collapsible>
						<Collapsible
							open={state.isUploadsOpen}
							onTriggerOpening={handleUploadsOpen}
							onTriggerClosing={handleUploadsClose}
							transitionTime={200}
							trigger={<Trigger title="home.uploads" icon={state.uploadsIsValid ? 'check' : 'pencil'} />}
						>
							<Uploads
								{...props}
								onClose={handleUploadsClose}
								onSave={handleUploadsClose}
								isOpen={state.isUploadsOpen}
							/>
						</Collapsible>
					</div>
				</div>
				<div className="button-group single right">
					<NavLink
						to="/summary"
						className="button"
						tabIndex={!state.allSectionsAreValid ? '-1' : null}
						disabled={!state.allSectionsAreValid || state.isPersonalDetailsOpen || state.isUploadsOpen}
					>
						<FormattedMessage id="home.validate"></FormattedMessage>
					</NavLink>
				</div>
				{false && process.env.DEV && (
					<div>
						<hr />
						<pre>{JSON.stringify(props.config.data, 0, 2)}</pre>
					</div>
				)}
			</div>
			<ModalComplete
				isOpen={isCompleteOpen}
				handleCompleteOk={handleCompleteOk}
				handleCompleteCancel={handleCompleteClose}
				title="home.modal_complete.title"
				text="home.modal_complete.text"
			/>
		</Layout>
	);
};

Home.propTypes = {
	config: PropTypes.object,
	onConfigUpdate: PropTypes.func,
};

export default Home;
