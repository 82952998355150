import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { getIn } from 'formik';

const InputRadio = (props) => {
	const [focus, setFocus] = useState(false);
	const { form, field, label, labelI18n, value, valueFormat, ...rest } = props;
	const classes = classNames({
		radio: true,
		focus: !!focus,
		error: form.touched[field.name] && form.errors[field.name],
	});

	const onFocus = (event) => {
		setFocus(true);
		if (props.onFocus) {
			props.onFocus(event);
		}
	};

	const onBlur = (event) => {
		setFocus(false);
		if (props.onBlur) {
			props.onBlur(event);
		}
	};

	return (
		<div className={classes}>
			<input
				{...field}
				{...rest}
				id={`${field.name}-${value}`}
				value={value}
				checked={getIn(form.values, field.name).toString() === value.toString()}
				onBlur={onBlur}
				onFocus={onFocus}
				onChange={() => {
					form.setFieldValue(field.name, valueFormat === 'number' ? Number(value) : value);
					if (rest.onChange) rest.onChange();
				}}
				type="radio"
			/>
			<label htmlFor={`${field.name}-${value}`}>{labelI18n || <FormattedMessage id={label}></FormattedMessage>}</label>
		</div>
	);
};

InputRadio.propTypes = {
	field: PropTypes.object,
	form: PropTypes.object,
	label: PropTypes.string,
	labelI18n: PropTypes.string,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	valueFormat: PropTypes.oneOf(['number', 'string']).isRequired,
};

export default InputRadio;
