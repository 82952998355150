import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import Input from '../components/Input';
import InputRadio from '../components/InputRadio';
import InputRadioGroup from '../components/InputRadioGroup';
import InputSelect from '../components/InputSelect';
import ErrorFocus from '../components/ErrorFocus';
import Data from '../modules/Data';
import Schema from '../modules/Schema';

const PersonalDetails = (props) => {
	const intl = useIntl();
	const formikFormRef = useRef();
	const { data } = props.config;
	const schema = Schema.load(Schema.personal, data);
	let initialValues = schema.cast(Data.dataToValues(Data.personal, data));

	const saveForm = (values) => {
		initialValues = Data.saveSection(Data.personal, values, data);
		props.onSave();
	};

	useEffect(() => {
		if (!props.isOpen && formikFormRef.current) {
			formikFormRef.current.resetForm();
		}
	}, [props.isOpen]);

	return (
		<Formik
			validateOnMount
			initialValues={initialValues}
			validationSchema={() => Yup.lazy((values) => Schema.load(Schema.personal, values))}
			innerRef={formikFormRef}
			onSubmit={saveForm}
			enableReinitialize
		>
			{({ values, errors, touched, setFieldValue, resetForm }) => (
				<Form>
					<InputRadioGroup label="forms.anrede" name="ANRED" values={values} errors={errors} touched={touched}>
						{intl.messages.ANRED.map((object) => (
							<Field
								component={InputRadio}
								name="ANRED"
								label="forms.missing"
								labelI18n={object.value}
								value={object.key}
								valueFormat="string"
								key={object.key}
							/>
						))}
					</InputRadioGroup>
					<Field
						component={Input}
						type="text"
						name="VORNA"
						label="forms.vorname"
						caption="forms.legalname"
						placeholder="forms.vorname_placeholder"
					/>
					<Field
						component={Input}
						type="text"
						name="NACHN"
						label="forms.nachname"
						caption="forms.legalname"
						placeholder="forms.nachname_placeholder"
					/>
					<Field
						component={Input}
						type="date"
						name="GBDAT"
						label="forms.geburtsdatum"
						placeholder="forms.datum_placeholder"
					/>
					<h3>
						<FormattedMessage id="section.wohnadr"></FormattedMessage>
					</h3>
					<Field
						component={Input}
						type="text"
						name="STRAS"
						label="forms.strasse"
						placeholder="forms.strasse_placeholder"
					/>
					<div className="input-group">
						<label htmlFor="plzort">
							<FormattedMessage id="forms.plzort"></FormattedMessage>
						</label>
						<div className="input-group-plzort">
							<Field
								component={Input}
								type="text"
								name="PSTLZ"
								placeholder="forms.plz_placeholder"
								maxLength={10}
								hideErrors
								onChange={(e) => {
									let { value } = e.target;
									value = value.toUpperCase();
									const regex = /^[A-Z0-9\-\s]{0,10}$/;
									if (value === '' || (value.length <= 10 && regex.test(value))) {
										setFieldValue('PSTLZ', value);
									}
								}}
							/>
							<Field component={Input} type="text" name="ORT01" placeholder="forms.ort_placeholder" hideErrors />
						</div>
						{(touched.PSTLZ || touched.ORT01) && (errors.PSTLZ || errors.ORT01) && (
							<div className="message">
								<FormattedMessage id="forms.plzort_error"></FormattedMessage>
							</div>
						)}
					</div>
					<Field
						component={InputSelect}
						name="LAND1"
						label="forms.land"
						placeholder="forms.please_select"
						options={intl.messages.LANDX}
						labelField="value"
						valueField="key"
					/>
					{values.LAND1 === 'CH' && (
						<Field
							component={InputSelect}
							name="STATE"
							label="forms.kanton"
							placeholder="forms.please_select"
							options={intl.messages.KANTO}
							labelField="value"
							valueField="key"
						/>
					)}
					<Field
						component={InputSelect}
						name="NATIO"
						label="forms.staatsangeh"
						placeholder="forms.please_select"
						options={intl.messages.LANDX}
						labelField="value"
						valueField="key"
					/>
					<Field
						component={InputSelect}
						name="SPRSL"
						label="forms.sprache"
						placeholder="forms.please_select"
						options={intl.messages.SPRSL}
						labelField="value"
						valueField="key"
					/>
					<ErrorFocus isActive={props.isOpen} />
					<div className="button-group move-down">
						<button
							type="button"
							className="button secondary"
							onClick={() => {
								props.onClose();
								resetForm();
							}}
						>
							<FormattedMessage id="button.cancel"></FormattedMessage>
						</button>
						<button type="submit" className="button">
							<FormattedMessage id="button.save"></FormattedMessage>
						</button>
					</div>
					<div className="required">
						<FormattedMessage id="forms.required_fields"></FormattedMessage>
					</div>
					{false && process.env.DEV && (
						<div>
							<hr />
							<pre>{JSON.stringify(values, 0, 2)}</pre>
							<pre>{JSON.stringify(errors, 0, 2)}</pre>
						</div>
					)}
				</Form>
			)}
		</Formik>
	);
};

PersonalDetails.propTypes = {
	config: PropTypes.object,
	onClose: PropTypes.func,
	onSave: PropTypes.func,
	isOpen: PropTypes.bool,
};

export default PersonalDetails;
