import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import Layout from './Layout';

const ServerError = ({ error, config, onConfigUpdate }) => {
	const intl = useIntl();

	const createMarkup = () => ({
		__html: intl.formatMessage({
			id: 'app.netzwerkfehler.text',
		}),
	});

	if (error.error === -100) {
		return (
			<Layout title="app.title" config={config} onConfigUpdate={onConfigUpdate}>
				<div className="content form login">
					<div className="content-inner">
						<h1 className="smaller">
							<FormattedMessage id="ratelimit.title"></FormattedMessage>
						</h1>
						<p>
							<FormattedMessage id="ratelimit.text"></FormattedMessage>
						</p>
					</div>
				</div>
			</Layout>
		);
	}

	return (
		<Layout title="app.title" config={config} onConfigUpdate={onConfigUpdate}>
			<div className="content form login">
				<div className="content-inner">
					<h1 className="smaller">
						<FormattedMessage id="app.netzwerkfehler"></FormattedMessage>
					</h1>
					<p dangerouslySetInnerHTML={createMarkup()}></p>
				</div>
				<p style={{ display: 'none' }}>
					{error.error} {error.result}
				</p>
			</div>
		</Layout>
	);
};

ServerError.propTypes = {
	config: PropTypes.object,
	error: PropTypes.object,
	onConfigUpdate: PropTypes.func,
};

export default ServerError;
