import React, { useReducer, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';
import Loader from '../components/Loader';
import ErrorBoundary from '../components/ErrorBoundary';
import Login from './Login';
import Logout from './Logout';
import { Messages } from '../modules/Messages';
import NotFound from './NotFound';
import Maintenance from './Maintenance';
import Completed from './Completed';
import ServerError from './ServerError';
import Home from './Home';
import Summary from './Summary';
import Confirmed from './Confirmed';
import BadRequest from './BadRequest';
import Data from '../modules/Data';
import '../styles/app.scss';

const App = () => {
	const initialState = {
		config: {
			language: navigator.language.split(/[-_]/)[0],
			isAuthenticated: false,
			isCompleted: false,
			home: '/home',
		},
		hash: false,
		error: null,
		isLoading: true,
		isServerError: false,
		isMaintenance: false,
		is404: false,
		msisdnTruncated: '',
		smsMock: false,
	};
	const reducer = (state, newState) => ({ ...state, ...newState });
	const [state, setState] = useReducer(reducer, initialState);

	useEffect(() => {
		axios.defaults.withCredentials = true;
		axios
			.post(`${process.env.APP_SERVER}`, {
				action: 'i18n',
			})
			.then((i18nresponse) => {
				// if (process.env.DEV) console.log(i18nresponse.data);
				if (i18nresponse.data.error < 0) {
					setState({
						isLoading: false,
						isServerError: true,
						error: i18nresponse.data,
					});
					window.history.replaceState({}, null, `/404`);
					return;
				}
				if (i18nresponse.data.page === 'maintenance') {
					setState({
						isLoading: false,
						isMaintenance: true,
					});
					return;
				}
				Messages.addFromPresets(i18nresponse.data.i18n);

				// store hash
				const hash = window.location.pathname.substr(1);
				if (hash.length === 64) {
					setState({ hash });
				}

				axios
					.post(`${process.env.APP_SERVER}`, { action: 'login', hash })
					.then((response) => {
						// if (process.env.DEV) console.log(response.data);
						if (response.data.error === 0) {
							let { config } = state;

							// authenticated
							if (response.data.authenticated) {
								config.isAuthenticated = !!response.data.authenticated;
							}

							// language
							if (response.data.language) {
								state.config.language = response.data.language;
								config.lang = response.data.language.substr(0, 2).toUpperCase();
								document.body.classList.add(`lang-${state.config.language}`);
							}

							// form data
							if (response.data.data) {
								config.data = Data.init(response.data.data);
								config.isAuthenticated = true;
								config = configPreprocess(config);
							}

							// navigate to page
							if (response.data.page && response.data.page !== 'maintenance') {
								if (response.data.page === 'login') {
									setState({
										msisdnTruncated: response.data.msisdn,
										smsMock: response.data.sms_mock,
										status: response.data.status,
									});
								}
								setState({
									config,
									isLoading: false,
								});
								window.history.replaceState({}, null, `/${response.data.page}`);
							} else {
								setState({
									config,
									isLoading: false,
									isMaintenance: true,
								});
							}
						} else if (response.data.error === -5) {
							setState({
								isLoading: false,
							});
							window.history.replaceState({}, null, `/400`);
						} else {
							serverError(response.data);
						}
					})
					.catch((error) => {
						serverError(error.message);
					});
			})
			.catch((error) => {
				console.log(error);
				serverError(error.message);
			});

		document.addEventListener('keydown', handleKey);

		return () => document.removeEventListener('keydown', handleKey);
	}, []);

	const configPreprocess = (config) => {
		if (state.config.language) config.lang = state.config.language.substr(0, 2).toUpperCase();
		return config;
	};

	const handleKey = (e) => {
		if (e.keyCode === 9) {
			document.body.classList.add('user-is-tabbing');
			document.removeEventListener('keydown', handleKey);
		}
	};

	const handleConfigUpdate = (config) => {
		config = configPreprocess(config);
		// if (process.env.DEV) console.log('new config', config);
		setState({ config: { ...state.config, ...config } });
	};

	const serverError = (response) => {
		setState({
			error: { error: response.error, response },
			isLoading: false,
			isServerError: true,
			is404: response.page === '404',
		});
	};

	const sharedParams = { config: state.config, hash: state.hash, onConfigUpdate: handleConfigUpdate };

	if (state.isLoading) {
		return <Loader></Loader>;
	}

	if (state.isMaintenance) {
		return (
			<IntlProvider
				locale={state.config.language}
				key={state.config.language}
				messages={Messages[state.config.language]}
			>
				<BrowserRouter>
					<Maintenance {...sharedParams} />
				</BrowserRouter>
			</IntlProvider>
		);
	}

	if (state.is404) {
		return (
			<IntlProvider
				locale={state.config.language}
				key={state.config.language}
				messages={Messages[state.config.language]}
			>
				<BrowserRouter>
					<NotFound {...sharedParams} />
				</BrowserRouter>
			</IntlProvider>
		);
	}

	if (state.isServerError) {
		return (
			<IntlProvider
				locale={state.config.language}
				key={state.config.language}
				messages={Messages[state.config.language]}
			>
				<BrowserRouter>
					<ServerError error={state.error} {...sharedParams} />
				</BrowserRouter>
			</IntlProvider>
		);
	}

	return (
		<IntlProvider locale={state.config.language} key={state.config.language} messages={Messages[state.config.language]}>
			<ErrorBoundary>
				<BrowserRouter>
					<Routes>
						<Route
							path="/login"
							exact
							element={
								<Login
									{...sharedParams}
									msisdnTruncated={state.msisdnTruncated}
									smsMock={state.smsMock}
									status={state.status}
								/>
							}
						/>
						<Route path="/logout" exact element={<Logout {...sharedParams} />} />
						<Route path="/completed" exact element={<Completed {...sharedParams} />} />
						<Route path="/404" exact element={<NotFound {...sharedParams} />} />
						<Route path="/400" exact element={<BadRequest {...sharedParams} />} />

						{state.config.isCompleted && <Route path="/confirmed" exact element={<Confirmed {...sharedParams} />} />}

						{state.config.isAuthenticated && !state.config.isCompleted && (
							<>
								<Route path="/home" exact element={<Home {...sharedParams} />} />
								<Route path="/summary" exact element={<Summary {...sharedParams} />} />
							</>
						)}

						{!state.config.isAuthenticated && (
							<>
								<Route path="/home" exact element={<Navigate to="/logout" replace />} />
								<Route path="/summary" exact element={<Navigate to="/logout" replace />} />
								<Route path="/confirmed" exact element={<Navigate to="/logout" replace />} />
							</>
						)}

						<Route path="/" element={<NotFound {...sharedParams} />} />
					</Routes>
				</BrowserRouter>
			</ErrorBoundary>
		</IntlProvider>
	);
};

export default App;
