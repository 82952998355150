import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';

const ErrorFocus = ({ formik, isActive }) => {
	useEffect(() => {
		const { isSubmitting, isValidating, errors } = formik;
		const keys = Object.keys(errors);
		if (isActive && keys.length > 0 && isSubmitting && !isValidating) {
			let element = document.querySelector(`input[name="${keys[0]}"],[data-name="${keys[0]}"]`);
			if (element) {
				const yOffset = -65;
				const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
				const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
				if (isSmoothScrollSupported) {
					setTimeout(() => {
						window.scrollTo({ top: y, behavior: 'smooth' });
					}, 10);
				} else {
					document.body.scrollTop = y;
					document.documentElement.scrollTop = y;
				}
				if (!element.classList.contains('input-file')) {
					setTimeout(() => {
						element = document.querySelector('.error input');
						if (element) element.focus();
					}, 600);
				}
			}
		}
	}, [formik, isActive]);
};

ErrorFocus.propTypes = {
	formik: PropTypes.object,
	isActive: PropTypes.bool,
};

export default connect(ErrorFocus);
